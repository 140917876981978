import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Fixed } from "@components/feature-list";
import StartButton from "@components/StartButton";
import DollarIcon from "@icons/dollar";

const Cashback = ({ reverse }) => {
  const images = useStaticQuery(graphql`
    query {
      xs: file(name: { eq: "cashbacks-feature" }) {
        sharp: childImageSharp {
          fixed(width: 400) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      sm: file(name: { eq: "cashbacks-feature" }) {
        sharp: childImageSharp {
          fixed(width: 700) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  const sources = [
    images.xs.sharp.fixed,
    {
      ...images.sm.sharp.fixed,
      media: `(min-width: 640px)`,
    },
  ];

  return (
    <Fixed
      description={<Description/>}
      image={sources}
      reverse={!!reverse}
    />
  );
}

const Description = () => (
  <div>
    <DollarIcon />
    <div className="mt-6">
      <h2>
        <span className="block text-base text-highlight font-semibold tracking-wide uppercase">
          Engagement | Loyalty
        </span>
        <span className="block text-3xl font-bold tracking-tight text-gray-900 mt-1">
          Cashbacks
        </span>
      </h2>
      <p className="mt-4 text-lg text-gray-500">
        The ability to connect with your customers is imperative.
        Cashback and rewards programs provide a platform where this engagement can start, by offering customers a cashback or &ldquo;gift with purchase&rdquo;.
        Avoid discounting your products, and gain valuable insights into your customers&rsquo; behaviour.
      </p>
      <p className="mt-4 text-lg text-gray-500">
        We offer a flexible approach, allowing you to choose between a fully managed service, or you can manage the process yourself.
        The technology that supports our products is built in-house, giving you the ability to make those personalised choices.
      </p>
      <p className="mt-4 text-lg text-gray-500">
        Your method of effectively generating loyalty and shopper tracking has now begun.
      </p>
      <div className="mt-6">
        <StartButton to="/cashbacks" />
      </div>
    </div>
  </div>
);

export default Cashback;
